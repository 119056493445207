<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="发布招聘信息"/>
		<RecruitmentForm @eventForm="handleGetForm"class="purForm"  />
	</div>
</template>

<script>
	import RecruitmentForm from '@/components/Recruitment/Form'
	export default {
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('发布成功');
					this.$router.push('/recruitment')
				})
			}
		},
		components: {
			RecruitmentForm
		}
	}
</script>
